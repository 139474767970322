import React, { Component } from 'react';
import { Image, Section } from '../../../components';

class Rukki extends Component {
    render() {
        const { data: { columns, block_id } } = this.props;
        const column = columns[0];
        return (
            <Section id={block_id} noPadding>
                <Image
                    src={column.images[0].image}
                    alt={column.images[0].image_title}
                    name={column.images[0].name}
                    container={column.images[0].container}
                    center={column.images[0].center}
                    align={column.images[0].align}
                    fade={column.images[0].fade}
                />
            </Section>
        );
    }
}

export default Rukki;