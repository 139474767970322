import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import LazyLoad from 'react-lazyload';

const Image = ({
    src,
    name,
    container,
    align,
    center,
    fade,
    background,
    alt,
    className,
    small,
}) => {
    const classes = classNames('image', {
        [`image--${name}`]: name,
        [`image--${align}`]: align,
        'image--background': background,
        'image--small': small,
        [` ${className}`]: className,
    });

    const image = (
        <LazyLoad offset={300} height={300}>
            <img className={classes} src={src} alt={alt} />
        </LazyLoad>
    );

    if (!container) return image;

    const containerClasses = classNames('image-container', {
        'image-container--center': center,
        'image-container--fade': fade,
    });

    return <div className={containerClasses}>{image}</div>;
};

Image.propTypes = {
    src: PropTypes.string,
    name: PropTypes.string,
    container: PropTypes.bool,
    center: PropTypes.bool,
    align: PropTypes.oneOf(['left', 'center', 'right']),
    fade: PropTypes.bool,
    background: PropTypes.bool,
};

export default Image;
