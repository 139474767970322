import React, { Component } from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import { Section, Grid, Content, Image } from '../../../components';
import { scrollAnimation } from '../../../globals';

class Default extends Component {
    renderColumn(column, index) {
        if (column.radio !== 'Content' && column.radio !== 'Image') return;
        return (
            <Grid.Col className={column.class} key={index}>
                {column.radio === 'Content' && (
                    <Content
                        title={column.title}
                        paragraphs={column.content}
                        align={column.align}
                    />
                )}
                {column.radio === 'Image' &&
                    column.images && (
                        <Image
                            src={column.images[0].image}
                            alt={column.images[0].image_title}
                            name={column.images[0].name}
                            container={column.images[0].container}
                            center={column.images[0].center}
                            align={column.images[0].align}
                        />
                    )}
            </Grid.Col>
        );
    }

    render() {
        const {
            data: { block_id, columns },
        } = this.props;
        return (
            <Section id={block_id}>
                <ScrollAnimation {...scrollAnimation}>
                    <Grid>
                        {columns &&
                            columns.map((column, index) => this.renderColumn(column, index))}
                    </Grid>
                </ScrollAnimation>
            </Section>
        );
    }
}

export default Default;
