import React from 'react';

const PrivacyPopup = ({ onAccept, togglePrivacyModal, translations }) => (
    <div className="privacy-popup fixed fixed--left fixed--bottom">
        <div className="wavy-border wavy-border--top"></div>
        <div className="privacy-popup__content">
            <div className="grid">
                <div className="grid__col--xs-12 text-center">
                    <h3 className="no-margin">{translations.privacy_popup_title}</h3>
                </div>
                <div className="grid__col--xs-12 text-center">
                    <p>{translations.privacy_popup_content}</p>
                    <a href="#0" onClick={togglePrivacyModal}>{translations.privacy_popup_link}</a>
                </div>
                <div className="grid__col--xs-12 text-center">
                    <button className="btn" onClick={onAccept}>{translations.privacy_popup_button_text}</button>
                </div>
            </div>
        </div>
    </div>
);

export default PrivacyPopup;