import React from 'react';
import PropTypes from 'prop-types';
import ScrollAnimation from 'react-animate-on-scroll';

import { Content, Grid, Image } from '../';
import { scrollAnimation } from '../../globals';

const Vodka = ({ title, shift, content, image }) => (
    <ScrollAnimation {...scrollAnimation}>
        <Grid align="middle-xs" className={shift ? 'vodka--shift' : ''}>
            <Grid.Col width="xs" className="text-right">
                <Image src={image} name="vodka-bottles" alt={title} />
            </Grid.Col>
            <Grid.Col width="xs-auto">
                <Content color="dark" title={title} paragraphs={content} condensed />
            </Grid.Col>
        </Grid>
    </ScrollAnimation>
);

Vodka.propTypes = {
    imgSrc: PropTypes.string,
    title: PropTypes.string,
    alt: PropTypes.string,
    paragraphs: PropTypes.array,
    shift: PropTypes.bool,
};

export default Vodka;
