import React, { Component } from 'react';
import { Cocktails, Default, Rukki, Special, Video, Vodkas, Image } from './components';

class ContentSelector extends Component {
    render() {
        const { data, acf, vodkas, cocktails } = this.props;
        if (!data) return;

        const components = {
            Cocktails,
            Rukki,
            Special,
            Video,
            Vodkas,
            Image,
        };

        let Tag = components[data.block_component];
        if (!Tag) Tag = Default;

        return <Tag data={data} acf={acf} vodkas={vodkas} cocktails={cocktails} />;
    }
}

export default ContentSelector;
