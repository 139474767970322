export const BREAKPOINTS = {
    xs: '320',
    sm: '800',
    md: '1000',
    lg: '1200',
    xl: '1680',
};

export const toggleBodyScroll = (isVisible, addOverlay) => {
    if (isVisible) {
        document.body.style.top = -window.scrollY + 'px';
        document.body.classList.add('scroll-disabled');

        if (addOverlay) {
            const element = document.createElement('div');
            element.classList.add('overlay');
            document.body.appendChild(element);
        }
    } else {
        document.body.classList.remove('scroll-disabled');
        const bodyScroll = parseInt(document.body.style.top, 10);
        if (bodyScroll) {
            document.documentElement.scrollTop = document.body.scrollTop = -bodyScroll;
            document.body.style.top = 0;
        }

        if (document.querySelector('.overlay')) {
            document.querySelector('.overlay').remove();
        }
    }
};

export const scrollTo = e => {
    e.preventDefault();

    const toElement = '#' + e.target.href.split('#')[1];
    document.querySelector(toElement).scrollIntoView({
        block: 'start',
        behavior: 'smooth',
    });
};

export const scrollAnimation = {
    animateIn: 'fadeIn',
    animateOut: 'fadeOut',
    duration: 1,
    offset: window.innerWidth < +BREAKPOINTS.sm ? 250 : 300,
};
