import React, { Component } from 'react';
import MediaQuery from 'react-responsive';

import { Collapse, Languages } from '../';
import { BREAKPOINTS, toggleBodyScroll, scrollTo } from '../../globals';

class Header extends Component {
    state = {
        menuVisible: false,
    };

    toggleMenuVisibility = (toggleScroll, visible, e) => {
        if (typeof e === 'object') {
            e.persist();
        }

        this.setState({ menuVisible: toggleScroll ? !this.state.menuVisible : visible }, () => {
            if (toggleScroll === true) {
                toggleBodyScroll(this.state.menuVisible);
            }
            if (typeof e === 'object') {
                scrollTo(e);
            }
        });
    };

    renderMobileHeader() {
        const { translations: { menu_logo, available_languages }} = this.props;

        return (
            <React.Fragment>
                <button
                    type="button"
                    className="header__button-mobile"
                    onClick={() => this.toggleMenuVisibility(true)}
                >
                    <span className="header__button-burger" />
                </button>
                <div className="header__content">
                    <a href="/">
                        <img className="header__logo" src={menu_logo} alt="Viru Valge logo" />
                    </a>
                    {this.renderMenu()}
                    <Languages languages={available_languages} />
                </div>
            </React.Fragment>
        );
    }

    renderDesktopHeader(menuVisible) {
        const { translations: { menu_logo, available_languages }} = this.props;

        return (
            <React.Fragment>
                <div
                    className="header__content"
                    onMouseEnter={() => this.toggleMenuVisibility(false, true)}
                    onMouseLeave={() => this.toggleMenuVisibility(false, false)}
                >
                    <a href="/">
                        <img className="header__logo" src={menu_logo} alt="Viru Valge logo" />
                    </a>
                    <Collapse isShown={menuVisible} id="menu-collapse">
                        {this.renderMenu()}
                        <Languages languages={available_languages} />
                    </Collapse>
                </div>
                <div className="wavy-border" />
            </React.Fragment>
        );
    }

    renderMenu() {
        const { translations: { menu } } = this.props;

        return (
            <ul className="header__nav">
                {menu.map((item, index) => {
                    return (
                        <li className="header__nav-item" key={index}>
                            <a
                                className="header__nav-link"
                                href={item.hook}
                                onClick={e => this.toggleMenuVisibility(true, false, e)}
                            >
                                {item.label}
                            </a>
                        </li>
                    );
                })}
            </ul>
        );
    }

    render() {
        const { menuVisible } = this.state;

        return (
            <header
                className={`header fixed fixed--top fixed--right ${
                    menuVisible ? 'is-visible' : ''
                }`}
            >
                <MediaQuery minWidth={+BREAKPOINTS.sm}>
                    {matches => {
                        if (matches) {
                            return this.renderDesktopHeader(menuVisible);
                        } else {
                            return this.renderMobileHeader(menuVisible);
                        }
                    }}
                </MediaQuery>
            </header>
        );
    }
}

export default Header;
