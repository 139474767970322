import React, { Component } from 'react';

import { Section, Grid, Cocktail } from '../../../components';

class Cocktails extends Component {
    render() {
        const { data: { block_id }, cocktails, acf } = this.props;

        if (cocktails.length === 0) return null;

        return (
            <Section type="padding" id={block_id}>
                <Grid>
                    {acf.page_cocktails.map((row, index) => {
                        const thisCocktail = cocktails.find(item => item.id === row.cocktail);

                        return (
                            <Grid.Col width="sm-6" key={index}>
                                <Cocktail title={thisCocktail.title.rendered} {...thisCocktail.acf} />
                            </Grid.Col>
                        );
                    })}
                </Grid>
            </Section>
        );
    }
}

export default Cocktails;
