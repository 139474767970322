import React, { Component } from 'react';
import Cookies from 'universal-cookie';
import {
    Header,
    PrivacyPopup,
    AgePopup,
    Section,
    Grid,
    Separator,
    Image,
    PrivacyModal,
    Contact,
} from '../components';
import { toggleBodyScroll } from '../globals';
import ApiService from '../api/api';

import ContentSelector from './ContentSelector/ContentSelector';

class App extends Component {
    constructor(props) {
        super(props);

        this.cookies = new Cookies();

        if (this.cookies.get('age') !== '1') {
            toggleBodyScroll(true, true);
        }

        this.state = {
            page: null,
            cocktails: [],
            vodkas: [],
            agePopupVisible: this.cookies.get('age') === '1' ? false : true,
            privacyPopupVisible: this.cookies.get('privacy') === '1' ? false : true,
            privacyModalVisible: false,
        };
    }

    async getData() {
        try {
            const data = await ApiService.getPage();
            this.setState({ page: data });

            const vodkaKeys = [];
            for (const row of data.acf.vodka_rows) {
                if (row.vodka_left) vodkaKeys.push(row.vodka_left);
                if (row.vodka_right) vodkaKeys.push(row.vodka_right);
            }

            const cocktailsKeys = [];
            for (const row of data.acf.page_cocktails) {
                cocktailsKeys.push(row.cocktail);
            }

            const vodkas = await ApiService.getVodkas(vodkaKeys);
            const cocktails = await ApiService.getCocktails(cocktailsKeys);
            this.setState({ vodkas, cocktails });
        } catch (error) {
            throw new Error(error);
        }
    }

    componentDidMount() {
        this.getData();
    }

    setCookie = type => {
        this.cookies.set(type, 1, { path: '/' });
        this.setState({ [`${type}PopupVisible`]: false }, () =>
            toggleBodyScroll(this.state.agePopupVisible),
        );
    };

    togglePrivacyModal = e => {
        e.preventDefault();

        this.setState({ privacyModalVisible: !this.state.privacyModalVisible }, () =>
            toggleBodyScroll(this.state.privacyModalVisible, true),
        );
    };

    render() {
        const {
            agePopupVisible,
            privacyPopupVisible,
            privacyModalVisible,
            page,
            cocktails,
            vodkas,
        } = this.state;

        if (!page) return <div />;

        const { acf } = page;

        return (
            <React.Fragment>
                <Header translations={acf} />
                {agePopupVisible && (
                    <AgePopup onConfirm={() => this.setCookie('age')} translations={acf} />
                )}
                {privacyPopupVisible && (
                    <PrivacyPopup
                        onAccept={() => this.setCookie('privacy')}
                        togglePrivacyModal={this.togglePrivacyModal}
                        translations={acf}
                    />
                )}
                {privacyModalVisible && (
                    <PrivacyModal onClose={this.togglePrivacyModal} translations={acf} />
                )}
                <p className="fixed fixed--bottom fixed--right fixed--zindex fixed--padding text-small text-right text-grey">
                    {acf.reminder}
                </p>
                <Section type="landing">
                    <Image className="section__vodka" src={acf.hero_image} alt="Landing image" />
                </Section>

                {acf.page_blocks &&
                    acf.page_blocks.map((block, index) => (
                        <ContentSelector
                            key={index}
                            data={block}
                            cocktails={block.block_component === 'Cocktails' ? cocktails : {}}
                            vodkas={block.block_component === 'Vodkas' ? vodkas : {}}
                            acf={['Vodkas', 'Cocktails'].includes(block.block_component)  ? acf : {}}
                        />
                    ))}

                <Section type="fullscreen" id="contact">
                    <Grid align="center-xs">
                        <Grid.Col width="sm-6" align="middle-xs">
                            <Image src={acf.contact_image} name="horn-2" alt="Pasunamees" />
                        </Grid.Col>
                        <Grid.Col width="sm" align="middle-xs">
                            <Contact title={acf.contact_title} contacts={acf.contacts} />
                        </Grid.Col>
                    </Grid>
                    <Separator size="small" />
                </Section>
            </React.Fragment>
        );
    }
}

export default App;
