import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Gradient = ({ children, horizontal, vertical, color, className }) => {
    const classes = classNames('gradient', {
        [`gradient--${horizontal}`]: horizontal,
        [`gradient--${vertical}`]: vertical,
        [`gradient--${color}`]: color,
        [''+className]: className,
    });

    return (
        <div className={classes}>
            {children}
        </div>
    );
};

Gradient.propTypes = {
    children: PropTypes.node,
    horizontal: PropTypes.oneOf([
        'left',
        'right',
    ]),
    vertical: PropTypes.oneOf([
        'top',
        'middle',
        'bottom',
        'center',
    ]),
    color: PropTypes.oneOf([
        'blue',
        'yellow',
        'green',
        'red',
    ]),
    className: PropTypes.string,
};

export default Gradient;