import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import ScrollAnimation from 'react-animate-on-scroll';
import { scrollAnimation } from '../../../globals';
import { Section, Video as VideoPlayer } from '../../../components';

class Video extends Component {
    videoRef = React.createRef();

    playVideo = visible => {
        if (visible.inViewport) {
            const promise = ReactDOM.findDOMNode(this.videoRef.current).play();

            if (promise !== undefined) {
                promise
                    .then(() => {
                        // Auto-play started
                        console.log('');
                    })
                    .catch(error => {
                        // Auto-play was prevented
                        console.warn(error);
                    });
            }
        } else {
            this.stopVideo();
        }
    };

    stopVideo = () => {
        ReactDOM.findDOMNode(this.videoRef.current).pause();
    };

    render() {
        const {
            data: { columns, block_id },
        } = this.props;

        if (!columns[0]) return <div />;

        return (
            <ScrollAnimation
                {...scrollAnimation}
                afterAnimatedIn={this.playVideo}
                afterAnimatedOut={this.stopVideo}
            >
                <Section id={block_id} background="blue">
                    <VideoPlayer
                        src={columns[0].video_file || ''}
                        srcMobile={columns[0].video_mobile_file || ''}
                        poster={columns[0].video_placeholder || ''}
                        ref={this.videoRef}
                    />
                </Section>
            </ScrollAnimation>
        );
    }
}

export default Video;
