import React, { Component } from 'react';
import { Section, Content, Image, Grid, Separator } from '../../../components';
import ScrollAnimation from 'react-animate-on-scroll';
import { scrollAnimation } from '../../../globals';

class Rukki extends Component {
    render() {
        const { data: { columns, block_id } } = this.props;
        if(!columns) return <div></div>;
        const textColumn = columns[0];
        const images = columns[1].images;

        return (
            <React.Fragment>
                <Separator size="huge" />
                <Section id={block_id}>
                    <ScrollAnimation {...scrollAnimation}>
                        <Grid>
                            <Grid.Col width="sm-7">
                                { images[0] && (
                                    <Image
                                        src={images[0].image}
                                        name="cornflower-bg"
                                        alt={images[0].image_title}
                                        container
                                    />
                                )}
                                { images[1] && (
                                    <Image
                                        src={images[1].image}
                                        name="cornflower-bottle"
                                        alt={images[1].image_title}
                                        container
                                        center
                                    />
                                )}
                            </Grid.Col>
                            <Grid.Col width="sm-5" order="first-xs">
                                <Content
                                    title={textColumn.title}
                                    paragraphs={textColumn.content}
                                />
                            </Grid.Col>
                        </Grid>
                    </ScrollAnimation>
                </Section>
            </React.Fragment>
        );
    }
}

export default Rukki;