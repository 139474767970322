import React from 'react';

const Languages = ({ languages }) => {
    const setLang = lang => {
        localStorage.setItem('lang', lang);
    };

    return (
        <ul className="languages">
            {languages.map((lang, index) => {
                return (
                    <li className="languages__item" key={index}>
                        <a
                            href={`?lang=${lang.hook}`}
                            onClick={() => setLang(lang.hook)}
                            className={`languages__link ${
                                localStorage.getItem('lang') === lang.hook ||
                                (!localStorage.getItem('lang') && lang.hook === 'et')
                                    ? 'is-active'
                                    : ''
                            }`}
                        >
                            {lang.label}
                        </a>
                    </li>
                );
            })}
        </ul>
    );
};

export default Languages;
