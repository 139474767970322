import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Content = ({ title, titleSize, paragraphs, align, color, size, condensed, className }) => {
    if (!paragraphs) return null;

    const classes = classNames('content', {
        [`content--${align}`]: align,
        [`content--${color}`]: color,
        [`content--${size}`]: size,
        'content--condensed': condensed,
        ['' + className]: className,
    });

    const TitleSize = titleSize || 'h3';

    return (
        <article className={classes}>
            <TitleSize className="content__title">
                {typeof title === 'string'
                    ? title
                    : title.map((row, index) => (
                          <React.Fragment key={index}>
                              {row}
                              {index !== title.length - 1 ? <br /> : null}
                          </React.Fragment>
                      ))}
            </TitleSize>
            {paragraphs.map((p, index) => (
                <p key={index} className="content__paragraph">
                    {typeof p.block === 'string'
                        ? p.block.split('\r\n').map((item, index) => (
                              <React.Fragment key={index}>
                                  {item}
                                  <br />
                              </React.Fragment>
                          ))
                        : p.map((row, index) => (
                              <React.Fragment key={index}>
                                  {row}
                                  {index !== p.block.length - 1 ? <br /> : null}
                              </React.Fragment>
                          ))}
                </p>
            ))}
        </article>
    );
};

Content.propTypes = {
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    paragraphs: PropTypes.arrayOf(
        PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.string)]),
    ),
    align: PropTypes.oneOf(['left', 'center', 'right']),
    color: PropTypes.oneOf(['dark', 'white']),
    size: PropTypes.oneOf(['small']),
    className: PropTypes.string,
};

export default Content;
