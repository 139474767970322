import React from 'react';
import { Languages, Grid } from '../';

const AgePopup = ({ onConfirm, translations }) => (
    <div className="age-popup">
        <div className="age-popup__container">
            <div className="age-popup__content">
                <Grid align="center-xs">
                    <Grid.Col width="xs-12" className="text-center">
                        <img className="age-popup__logo" src={translations.age_popup_logo} alt="logo" />
                    </Grid.Col>
                    <Grid.Col width="xs-12" className="text-center">
                        <h3>{translations.age_popup_title}</h3>
                    </Grid.Col>
                    <Grid.Col width="xs-12" className="text-center">
                        <button type="button" className="btn" onClick={onConfirm}>
                            {translations.age_popup_button_text}
                        </button>
                    </Grid.Col>
                    <Grid.Col width="xs-12">
                        <Languages languages={translations.available_languages} />
                    </Grid.Col>
                </Grid>
            </div>
            <div className="wavy-border wavy-border--white" />
        </div>
    </div>
);

export default AgePopup;
