import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';

import { Image, Grid } from '../';
import { scrollAnimation } from '../../globals';

const Cocktail = ({ image, title, description, contents }) => {
    if (!contents) return null;

    return (
        <ScrollAnimation {...scrollAnimation} className="cocktail">
            <Grid align="middle-sm">
                <Grid.Col width="xs-12">
                    <Image src={image} className="cocktail__image" alt="" />
                </Grid.Col>
                <Grid.Col width="xs-12">
                    <div className="content content--dark content--small cocktail__content cocktail__content--center">
                        <h3 className="content__title">{title}</h3>
                        <p className="content__paragraph">
                            {contents.map((item, index) => {
                                return `${item.content_element}${index === contents.length - 1 ? '' : ', '}`
                            })}
                        </p>
                        <p className="content__paragraph">{description}</p>
                    </div>
                </Grid.Col>
            </Grid>
        </ScrollAnimation>
    );
};

export default Cocktail;
