import React from 'react';

const Contact = ({ title, contacts }) => (
    <div className="contact">
        <h2 className="h3 contact__title">{title}</h2>
        <ul className="contact__list">
            {contacts && contacts.map((contact, index) => (
                <li key={index}>
                    <a className="contact__link" href={contact.hook} target={contact.target}>{contact.label}</a>
                    <img className="contact__icon" src={contact.image} alt={contact.label} />
                </li>
            ))}
        </ul>
    </div>
);

export default Contact;