import React, { Component } from 'react';
import ScrollAnimation from 'react-animate-on-scroll';

import { Section, Image, Grid, Content, Separator } from '../../../components';
import { scrollAnimation } from '../../../globals';

class Special extends Component {
    render() {
        const {
            data: { block_id, columns },
        } = this.props;

        if (!columns) return null;

        return (
            <React.Fragment>
                <Separator size="large" />
                <ScrollAnimation {...scrollAnimation}>
                    <Section background="image" id={block_id}>
                        <Image
                            src={columns[0].images[0].image}
                            background
                            alt={columns[0].images[0].image_title}
                        />
                        <Grid align="center-xs,middle-sm">
                            <Grid.Col width="xs-5,sm-3" order="last-xs,original-sm" offset="sm-3">
                                <Image
                                    src={columns[0].images[2].image}
                                    alt={columns[0].images[2].image_title}
                                />
                            </Grid.Col>
                            <Grid.Col width="sm-5" offset="sm-1">
                                <Grid>
                                    <Grid.Col width="sm-11" offset="sm-1">
                                        <Image
                                            src={columns[0].images[1].image}
                                            alt={columns[0].images[1].image_title}
                                        />
                                    </Grid.Col>
                                    <Grid.Col width="sm-10" offset="sm-2">
                                        <Content
                                            align="right"
                                            color="white"
                                            title={columns[1].title}
                                            paragraphs={columns[1].content}
                                        />
                                    </Grid.Col>
                                </Grid>
                            </Grid.Col>
                        </Grid>
                    </Section>
                </ScrollAnimation>
            </React.Fragment>
        );
    }
}

export default Special;
