import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

class Collapse extends Component {
    state = {
        transitioning: false,
        hidden: !this.props.isShown,
    }

    componentWillReceiveProps({ isShown }) {
        if (isShown !== this.props.isShown) this.toggle(isShown);
    }

    toggle = visible => {
        const { transitioning } = this.state;

        if (transitioning) {
            this.setHeight(visible);
        } else {
            this.collapse.addEventListener('transitionend', () => this.end());
            if (visible) {
                this.collapse.style.height = 0;
            } else {
                this.collapse.style.height = this.collapse.scrollHeight + 'px';
            }
            setTimeout(() => {
                this.setState({ hidden: false, transitioning: true }, () => {
                    this.setHeight(visible);
                });
            }, 10);
        }
    }

    setHeight = visible => {
        if (visible) {
            this.collapse.style.height = this.collapse.scrollHeight + 'px';
        } else {
            this.collapse.style.height = 0 + 'px';
        }
    }

    end() {
        this.setState(
            {
                transitioning: false,
                hidden: !this.props.isShown,
            },
            () => {
                this.collapse.removeEventListener('transitionend', this.end);
                this.collapse.style.height = null;
            },
        );
    }

    render() {
        const { children } = this.props;
        const { transitioning, hidden } = this.state;

        const classes = classNames('collapse', {
            'collapsing': transitioning,
            'show': !hidden,
        });

        return (
            <div ref={el => el && (this.collapse = el)} className={classes}>
                {children}
            </div>
        );
    }
}

Collapse.propTypes = {
    isShown: PropTypes.bool.isRequired,
};

export default Collapse;
