import React, { Component } from 'react';

import { Grid, Vodka, Gradient, Section } from '../../../components';

class Vodkas extends Component {
    render() {
        const { acf, vodkas, data: { block_id } } = this.props;

        if (vodkas.length === 0) return null;

        return (
            <Section id={block_id}>
                {acf.vodka_rows.map((row, i) => {
                    const vodkaLeft = vodkas.find(item => item.id === row.vodka_left);
                    const vodkaRight = vodkas.find(item => item.id === row.vodka_right);

                    return (
                        <React.Fragment key={i}>
                            <Gradient
                                color={row.gradient_color}
                                horizontal={row.gradient_side}
                                vertical={row.gradient_position}
                            >
                                <Grid>
                                    <Grid.Col width="sm-auto">
                                        {vodkaLeft && <Vodka
                                            title={vodkaLeft.title.rendered}
                                            {...vodkaLeft.acf}
                                            shift={true}
                                        />}
                                    </Grid.Col>
                                    <Grid.Col width="sm-auto" offset="sm-1">
                                        {vodkaRight && <Vodka
                                            title={vodkaRight.title.rendered}
                                            {...vodkaRight.acf}
                                        />}
                                    </Grid.Col>
                                </Grid>
                            </Gradient>
                        </React.Fragment>
                    );
                })}
            </Section>
        );
    }
}

export default Vodkas;
