import 'whatwg-fetch';

export const makeFetch = async (url, options) => {
  try {
    const response = await window.fetch(url, options);
    return await response.json();
  } catch (e) {
    console.log(e);
    throw new Error(e);
  }
};
