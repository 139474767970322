import { makeFetch } from './helper';

const BASE_URL = '/wp-json/wp/v2';

class ApiService {
    lang = localStorage.getItem('lang') || 'et';
    langSearch = `lang=${this.lang}`;

    getPage() {
        return makeFetch(`${BASE_URL}/pages?${this.langSearch}`).then(res => res[0]);
    }

    getCocktails(cocktailKeys) {
        const search = this.makeSearchString(cocktailKeys);

        return makeFetch(`${BASE_URL}/cocktails${search}${this.langSearch}`);
    }

    getVodkas(vodkaKeys) {
        const search = this.makeSearchString(vodkaKeys);

        return makeFetch(`${BASE_URL}/vodkas${search}${this.langSearch}`);
    }

    makeSearchString(array) {
        let search = '?';

        for (const k in array) {
            search += `include[]=${array[k]}`;

            if (k !== array.length - 1) {
                search += '&';
            }
        }
        return search;
    }
}

export default new ApiService();
