import React from 'react';
import ReactDOM from 'react-dom';
import 'core-js';
import 'element-remove';

import './assets/sass/main.scss';
import { App } from './containers';

document.documentElement.setAttribute(
  'lang',
  localStorage.getItem('lang') || 'et',
);

ReactDOM.render(<App />, document.getElementById('root'));
