import React, { Component } from 'react';
import LazyLoad from 'react-lazyload';
import MediaQuery from 'react-responsive';

import { BREAKPOINTS } from '../../globals';

class Video extends Component {
    render() {
        const { src, srcMobile, poster } = this.props;

        return (
            <LazyLoad offset={300} height={300}>
                <video className="video" controls muted poster={poster}>
                    <MediaQuery minWidth={+BREAKPOINTS.sm}>
                        {matches => {
                            if (matches) {
                                return (<source src={src} type="video/mp4"/>)
                            } else {
                                return (<source src={srcMobile} type="video/mp4"/>)
                            }
                        }}
                    </MediaQuery>
                </video>
            </LazyLoad>
        );
    }
}

export default Video;