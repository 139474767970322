import React from 'react';

import { Modal, Content } from '../';

const PrivacyModal = ({ onClose, translations }) => (
    <Modal onCloseClick={onClose}>
        <h1 className="h3">{translations.modal_title}</h1>
        {translations.modal_content.map((content, index) =>
            <Content title={content.sub_title} titleSize="h4" paragraphs={content.content} key={index} />
        )}
    </Modal>
);

export default PrivacyModal;