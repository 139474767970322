import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Separator = ({ size }) => {
    const classes = classNames('separator', {
        [`separator--${size}`]: size,
    });

    return <hr className={classes} />;
};

Separator.propTypes = {
    size: PropTypes.oneOf([
        'small',
        'large',
        'huge',
    ]),
};

export default Separator;