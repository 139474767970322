import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Section = ({ children, background, className, type, style, id, noPadding }) => {
    const classes = classNames('section', {
        [`section--${background}`]: background,
        [`section--${type}`]: type,
        ['' + className]: className,
        'section--no-padding': noPadding,
    });

    const content = <div className="section__container">{children}</div>;

    return (
        <section className={classes} style={style} id={id}>
            {background ? <div className="section__background">{content}</div> : content}
        </section>
    );
};

Section.propTypes = {
    children: PropTypes.node,
    background: PropTypes.oneOf(['blue', 'image']),
    className: PropTypes.string,
    type: PropTypes.oneOf(['fullscreen', 'padding', 'landing']),
    noPadding: PropTypes.bool,
};

export default Section;
