import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Col extends Component {
    getBEM() {
        let bem = '';

        if (this.props.width)
            this.props.width.split(',').forEach(width => {
                width = width.trim();
                if (/(xs|sm|md|lg)(-[1-9]+)?/.test(width))
                    bem += ' grid__col--' + width;
            });

        if (this.props.offset)
            this.props.offset.split(',').forEach(offset => {
                offset = offset.trim();
                if (/(xs|sm|md|lg)(-[1-9]+)?/.test(offset))
                    bem += ' grid__col--offset-' + offset;
            });

        if (this.props.order)
            this.props.order.split(',').forEach(order => {
                order = order.trim();
                if (/(first|last|original)-(xs|sm|md|lg)/.test(order))
                    bem += ' grid__col--' + order;
            });

        if (this.props.align)
            this.props.align.split(',').forEach(align => {
                align = align.trim();
                if (/(top|middle|bottom)-(xs|sm|md|lg)/.test(align))
                    bem += ' grid__col--' + align;
            });

        if (this.props['no-gutter']) bem += ' grid__col--no-vertical-gutter';

        return this.props.className ? bem + ' ' + this.props.className : bem;
    }

    render() {
        return <div className={this.getBEM()}>{this.props.children}</div>;
    }
}

Col.propTypes = {
    className: PropTypes.string,
    width: PropTypes.string,
    offset: PropTypes.string,
    order: PropTypes.string,
    align: PropTypes.string,
    'no-gutter': PropTypes.bool,
};

Col.defaultProps = {
    children: '',
};

export default Col;
