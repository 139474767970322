import React from 'react';

const Modal = ({ children, onCloseClick }) => (
    <div className="modal">
        <div className="modal__container">
            <div className="modal__content">
                <button type="button" className="modal__close" onClick={onCloseClick}>
                    <svg className="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35.36 35.36"><polygon points="35.35 2.83 32.53 0 17.68 14.85 2.83 0 0 2.83 14.85 17.68 0 32.53 2.83 35.35 17.68 20.51 32.53 35.35 35.35 32.53 20.51 17.68 35.35 2.83"/></svg>
                </button>
                {children}
            </div>
        </div>
    </div>
);

export default Modal;